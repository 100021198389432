import { useRouter } from 'next/router'
import { useCollectionApi } from '~/common/api/product'
import { ShopDetail } from '~/model/shop'
import Product from '~/model/product'

export const topPageTitle = () => {
  return '【公式】ネットスーパー「マルクト」- TOPページ'
}

export const shopAreaTopPageTitle = (name: string) => {
  return `【マルクト】${name} - TOPページ`
}

export const pageTitle = (title: string) => {
  return `${title}【マルクト】`
}

export const shopPageTitle = (title: string, shop: ShopDetail | undefined) => {
  const shopName = shop?.baseInfo.name
  return pageTitle(`${title}` + (shopName ? `-${shopName}` : ''))
}

export const productPageTitle = (product: Product | undefined, shop?: ShopDetail | undefined) => {
  return shopPageTitle(product?.name ?? '', shop)
}

export const useSearchProductResultTitle = (shopKey: string = '') => {
  const { query } = useRouter()
  const collectionCode = query.collection as string | undefined
  const { data: collection } = useCollectionApi(collectionCode, shopKey)
  return collection ? collection.collection_name : query.pickup_product ? 'ピックアップ商品一覧' : query.coupon ? `${query.coupon}を利用できる商品` : query.name ? '検索結果' : '全商品'
}
