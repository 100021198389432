import { Color } from '~/common/utils/color'
import { useMounted } from '~/common/utils/useMounted'
import { useProductListByIdsAPi } from '~/common/api/product'
import { getProductRecentBrowsedIds } from '~/common/app/user'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import Content from '~/components/layouts/area/Content'
import TitleList from '~/components/layouts/title/TitleList'
import Loading from '~/components/atoms/loading/Loading'

interface Props {
  center?: boolean
}

const ProductRecentBrowsedList = ({ center = false }: Props) => {

  const mounted = useMounted()
  const productIds = getProductRecentBrowsedIds()
  const { data: products, isLoading } = useProductListByIdsAPi(productIds)

  return mounted && (
    <>
      {productIds &&
        <Content scroll center={center}>
          <TitleList>
            <BlockTitle icon={IconType.TIMER} iconColor={Color.SUPPORT_SUB}>
              最近閲覧した商品
            </BlockTitle>
          </TitleList>
          {isLoading &&
            <Loading />
          }
          {products && products.length > 0 && (
            <ProductScrollList type={ProductCardType.MICRO} products={products} />
          )}
        </Content>
      }
    </>
  )
}

export default ProductRecentBrowsedList