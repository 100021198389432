import { MouseEvent } from 'react'
import css from 'styled-jsx/css'
import { addClass } from '~/common/utils/html'
import InternalLink from '~/components/utils/link/InternalLink'

export const SelectorItemType = {
  CHIC: 'CHIC',
  POPULAR: 'POPULAR'
} as const

export type SelectorItemType = (typeof SelectorItemType)[keyof typeof SelectorItemType]

export const colorTypeClass = (type: SelectorItemType): string => {
  switch (type) {
    case SelectorItemType.CHIC:
      return 'c-selector-slide-item--type-chic'
    case SelectorItemType.POPULAR:
      return 'c-selector-slide-item--type-popular'
  }
}

interface Props {
  text: string
  link: string
  current?: boolean
  type?: SelectorItemType
  color?: string

  onClick?(e: MouseEvent<HTMLElement>): void
}

const SlideSelectorItem = ({
  text,
  link,
  current = false,
  type = SelectorItemType.CHIC,
  color
}: Props) => {
  const { className, styles: chicCurrentColor } = css.resolve`
    color: ${color};
  `
  return (
    <>
      <li
        className={
          'c-selector-slide-item' +
          addClass('c-selector-slide-item--current', current) +
          addClass(colorTypeClass(type))
        }
      >
        <InternalLink link={link} className={'c-selector-slide-item__link' + addClass(className)}>
          {text}
        </InternalLink>
      </li>
      <style jsx>{`
        .c-selector-slide-item--type-chic.c-selector-slide-item--current {
          border-bottom: 2px solid ${color};
          color: ${color};
        }
        .c-selector-slide-item__link {
          color: ${color};
        }
      `}</style>
      {chicCurrentColor}
    </>
  )
}
export default SlideSelectorItem
