import { Color } from '~/common/utils/color'
import { useCategoryList } from '~/common/api/category'
import { productListPath, savedImagePath, usePathKey } from '~/common/app/path'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import CategoryButton from '~/components/atoms/button/CategoryButton'
import CategoryButtonList from '~/components/layouts/button/CategoryButtonList'
import Content from '~/components/layouts/area/Content'

const CategoryList = () => {
  const key = usePathKey()
  const categories = useCategoryList()

  return (
    <Content scroll>
      <BlockTitle icon={IconType.CATEGORY} iconColor={Color.SUPPORT_SUB}>カテゴリから探す</BlockTitle>
      <CategoryButtonList>
        {categories?.map((category) =>
          <CategoryButton key={category.id} image={savedImagePath(category.image_file)}
                          link={productListPath({ category_id: category.id, key })}>
            {category.name}
          </CategoryButton>
        )}
      </CategoryButtonList>
    </Content>
  )
}

export default CategoryList
