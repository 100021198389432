import { useFavoriteProductList } from '~/common/api/product'

import { useShop } from '~/common/api/shop'

import { useProductsWithCoupon } from '~/common/app/product'
import { myPageFavoritePath } from '~/common/app/path'
import { Color } from '~/common/utils/color'
import ProductScrollList from '~/components/app/module/product/ProductScrollList'
import BlockTitle from '~/components/atoms/title/BlockTitle'
import { IconType } from '~/components/atoms/icon/Icon'
import LinkText from '~/components/atoms/text/LinkText'
import Content from '~/components/layouts/area/Content'
import TitleList from '~/components/layouts/title/TitleList'
import { ProductCardType } from '~/components/molecules/card/BaseProductCard'
import Loading from '~/components/atoms/loading/Loading'

const PRODUCT_FAVORITE_BLOCK_LIMIT = 30

interface Props {
  center?: boolean
}

const ProductFavoriteList = ({ center = false }: Props) => {
  const shop = useShop()
  const { products: productList, isLoading } = useFavoriteProductList({ isFilterLoggedInShop: !!shop });
  const products = useProductsWithCoupon(productList)

  return (
    <Content scroll center={center}>
      <TitleList>
        <BlockTitle icon={IconType.STAR} iconColor={Color.EMPHASIS_SUB}>
          お気に入り商品
        </BlockTitle>
        {products && products.length > 0 &&
          <LinkText arrow={Color.LINK} link={myPageFavoritePath()} px={12}>
            すべて見る
          </LinkText>
        }
      </TitleList>
      {products && products.length > 0 &&
        <ProductScrollList
          type={ProductCardType.SMALL}
          products={products.slice(0, PRODUCT_FAVORITE_BLOCK_LIMIT)}
          link={myPageFavoritePath()}
        />
      }
      {isLoading &&
        <Loading />
      }
      {!isLoading && products?.length == 0 &&
        <>
          {shop ?
            <>登録されている商品がありません。この店舗の商品をお気に入りに登録するとこちらに表示されます。</> :
            <>登録されている商品がありません。お気に入り商品を登録するとこちらに表示されます。</>
          }
        </>
      }
    </Content>
  )
}

export default ProductFavoriteList
