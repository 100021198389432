import { useShop, useShopList } from '~/common/api/shop'
import { useArea } from '~/common/api/area'
import { areaTopPath, shopTopPath, topPath, usePathKey } from '~/common/app/path'
import { Color } from '~/common/utils/color'
import SlideSelectorList from '~/components/atoms/selector/SlideSelectorList'
import SlideSelectorItem from '~/components/atoms/selector/SlideSelectorItem'

interface Props {
  bottomSpace?: boolean
}

const ShopLinkList = ({ bottomSpace = false }: Props) => {
  const key = usePathKey()
  const area = useArea()
  const currentShop = useShop()
  const shops = useShopList()

  return (
    <SlideSelectorList bottomSpace={bottomSpace}>
      <SlideSelectorItem key={'all'} text={'すべての店舗'}
                         link={area ? areaTopPath(area) : topPath()}
                         color={currentShop?.theme_color ?? Color.SUPPORT_SUB}
                         current={!currentShop} />
      {shops.map((shop) =>
        <SlideSelectorItem key={shop.baseInfo.id} text={shop.baseInfo.name}
                           link={shopTopPath(shop.baseInfo)}
                           color={shop.baseInfo.shop_key == key ? '#' + currentShop?.theme_color : undefined}
                           current={shop.baseInfo.shop_key == key} />
      )}
    </SlideSelectorList>
  )
}

export default ShopLinkList
