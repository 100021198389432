import Image from 'next/image'
import { ChildProps } from '~/common/utils/props'
import InternalLink from '~/components/utils/link/InternalLink'

interface Props extends ChildProps {
  image: string
  link: string
}

const CategoryButton = ({ children, image, link }: Props) => {
  return (
    <InternalLink link={link} className={'c-category-button'}>
      <Image src={image} alt={''} width={65} height={32} />
      <p className='c-category-button__text'>{children}</p>
    </InternalLink>
  )
}

export default CategoryButton
