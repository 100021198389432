import { ShopDetail } from '~/model/shop'
import { Mode, useSwitchMode, useUserMode } from '~/common/api/user'
import { Color } from '~/common/utils/color'
import { useMakeShopNextAcceptableMessage } from '~/common/api/shop'
import { useScrollToTag } from '~/common/utils/scroll'
import { AcceptableMessageType } from '~/common/app/delivery'
import { contactPath, savedImagePath, shopTopPath } from '~/common/app/path'
import { SHOP_INFO_TAG_ID } from '~/common/app/shop'
import HeaderFrame from '~/components/layouts/header/HeaderFrame'
import HeaderList from '~/components/layouts/header/HeaderList'
import LogoImage from '~/components/atoms/header/LogoImage'
import Segments, { SegmentsType } from '~/components/layouts/area/Segments'
import Text from '~/components/atoms/text/Text'
import SwitchButton, { SwitchButtonStatus } from '~/components/atoms/button/SwitchButton'
import HiddenSp from '~/components/utils/hidden/HiddenSp'
import LinkText from '~/components/atoms/text/LinkText'
import MarginTop from '~/components/utils/margin/MarginTop'

interface Props {
  shop: ShopDetail
}

const ShopHeader = ({ shop }: Props) => {
  const mode = useUserMode()
  const scrollToTag = useScrollToTag(SHOP_INFO_TAG_ID, true)
  const switchMode = useSwitchMode()
  const makeShopNextAcceptableMessage = useMakeShopNextAcceptableMessage(AcceptableMessageType.CLOSE)
  const message = makeShopNextAcceptableMessage(shop.baseInfo)

  const onClickDelivery = () => {
    mode == Mode.PICKUP && switchMode()
  }

  const onClickPickup = () => {
    mode == Mode.DELIVERY && switchMode()
  }

  return (
    <HeaderFrame color={'#' + shop.theme_color} shop gap={8} tag={'div'}>
      <HeaderList pcGap={16} flex>
        <LogoImage
          src={savedImagePath(shop.logo_image_file)}
          alt={shop.baseInfo.name}
          link={shopTopPath(shop.baseInfo)}
          type={'high'}
        />
        <Segments
          pcGap={2}
          spColumn={SegmentsType.COLUMN}
          pcColumn={SegmentsType.COLUMN}
          flex
          flexMin
        >
          <Text px={13} pcPx={16} color={Color.INVERSION} bold omit wide>
            {shop.baseInfo.name}
          </Text>
          <Text px={10} pcPx={12} color={Color.INVERSION}>
            {message}
          </Text>
        </Segments>
      </HeaderList>
      <HeaderList pcGap={16}>
        <HiddenSp>
          <div>
            <LinkText color={Color.INVERSION} px={12} onClick={scrollToTag}
                      nowrap>
              店舗情報
            </LinkText>
            <MarginTop marginPc={2}>
              <LinkText color={Color.INVERSION} px={12} link={contactPath(shop.baseInfo)}
                        nowrap>
                店舗へのお問い合わせ
              </LinkText>
            </MarginTop>
          </div>
          <style jsx>{`
            div {
              text-align: right;
            }
          `}</style>
        </HiddenSp>
        {mode != undefined &&
          <Segments spAlignCenter pcAlignCenter>
            <SwitchButton
              status={mode ? SwitchButtonStatus.ENABLE : SwitchButtonStatus.SELECTED}
              onClick={onClickDelivery}
            />
            <SwitchButton status={shop.enable_shop_pickup ?
              mode ? SwitchButtonStatus.SELECTED : SwitchButtonStatus.ENABLE : SwitchButtonStatus.DISABLE}
                          onClick={onClickPickup}
                          isStorePick
            />
          </Segments>
        }
      </HeaderList>
    </HeaderFrame>
  )
}

export default ShopHeader
