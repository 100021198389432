import React from 'react'
import Head from 'next/head'

interface Props {
  title: string
  // description: string
  // keywords: string
  path?: string
  imagePath?: string
  isTop?: boolean
}

/* TODO: Change description and keywords by page in the future function */

const MetaHead = ({
  title, /*description, keywords,*/
  path = '',
  imagePath,
  isTop = false
}: Props) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property='og:type' content={isTop ? 'website' : 'article'} />
      <meta property='og:url' content={process.env.WEB_URL + path} />
      {/*<meta property="description" content={description}/>*/}
      {/*<meta property="keywords" content={keywords}/>*/}
      {imagePath &&
        <meta property='og:image'
              content={process.env.WEB_URL + imagePath} />
      }
    </Head>
  )
}

export default MetaHead
