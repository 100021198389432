import { addClass } from '~/common/utils/html'
import { ClickProps } from '~/common/utils/props'

export const SwitchButtonStatus = {
  ENABLE: 'enable',
  DISABLE: 'disable',
  SELECTED: 'selected'
} as const
export type SwitchButtonStatus = (typeof SwitchButtonStatus)[keyof typeof SwitchButtonStatus]

export const buttonStatusText = (status: SwitchButtonStatus): string => {
  switch (status) {
    case SwitchButtonStatus.ENABLE:
      return '変更可'
    case SwitchButtonStatus.DISABLE:
      return '不可'
    case SwitchButtonStatus.SELECTED:
      return '選択中'
  }
}

export const buttonStatusClass = (status: SwitchButtonStatus): string => {
  switch (status) {
    case SwitchButtonStatus.ENABLE:
      return 'is-able'
    case SwitchButtonStatus.DISABLE:
      return 'is-disabled'
    case SwitchButtonStatus.SELECTED:
      return 'is-choosed'
  }
}

export const isStorePickClass = (isStorePick: boolean): string => {
  return isStorePick ? 'is-store-pick' : 'is-postage'
}

export const isStorePickLabel = (isStorePick: boolean): string => {
  return isStorePick ? '店舗受取' : '配達'
}

interface Props extends ClickProps {
  status: SwitchButtonStatus
  isStorePick?: boolean
}

const SwitchButton = ({ onClick, status, isStorePick = false }: Props) => {
  return (
    <>
      <button
        className={
          'c-switch-button__wrap' +
          addClass(isStorePickClass(isStorePick)) +
          addClass(buttonStatusClass(status))
        }
        onClick={onClick}
      >
        <div className='c-switch-button__definition'>
          <div className='c-switch-button__terms'>{isStorePickLabel(isStorePick)}</div>
          <div className={'c-switch-button__description'}>{buttonStatusText(status)}</div>
        </div>
      </button>
    </>
  )
}

export default SwitchButton
