import { ChildProps } from '~/common/utils/props'

const CategoryButtonList = ({ children }: ChildProps) => {
  return (
    <div className={'l-category-button-list'}>
      <div className={'l-category-button-list__in'}>
        {children}
      </div>
    </div>
  )
}

export default CategoryButtonList
