import { ChildProps } from '~/common/utils/props'
import { addClass } from '~/common/utils/html'

interface Props extends ChildProps {
  bottomSpace?: boolean
}

const SlideSelectorList = ({ children, bottomSpace = false }: Props) => {
  return (
    <ul
      className={'c-selector-slide-list' + addClass('c-selector-slide-list--bottom', bottomSpace)}>
      {children}
    </ul>
  )
}
export default SlideSelectorList
